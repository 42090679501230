import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
} from "../components/parts"

const AfricasVoices = () => (
  <Layout title="Africa's voices">
    <Title heading="Africa's voices" sub="Amplifying the voices of citizens" />
    <Overview
      project="africas-voices"
      text={[
        "Africa's voices turns citizens' authentic, large-scale voices into rigorous social insights useful for policymakers.",
        "I have contributed to interactive visual explainers, tools for rapid data exploration, and the visualization layer of the stack.",
      ]}
    />
    <Project
      heading="Interactive explainer"
      sub="Rapid social insights for better interventions in health crises."
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/africas-voices/1.jpg"
        alt="interactive"
        className="bordered"
      />
    </div>
    <Visit
      websites={[
        { url: "http://explain.avf.world/", label: "Interactive experience" },
      ]}
    />
    <Toolset project="africas-voices" />
    <Project
      heading="Live social insights from conversations"
      sub="Visualising themes & messages"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/africas-voices/2.png"
        alt="dashboard"
        className="bordered"
      />
    </div>
    <Toolset project="africas-voices" />
    <Project
      heading="Tools for rapid data explortion"
      sub="Dashboard to visualise & filter cohorts of participants and themes"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/africas-voices/3.png"
        alt="dashboard"
        className="bordered"
      />
    </div>
    <Toolset project="africas-voices" />
    <Involvement project="africas-voices" />
    <Visit project="africas-voices" />
    <WorkedWith project="africas-voices" />
  </Layout>
)

export default AfricasVoices
